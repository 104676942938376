<template>
  <div>
    <v-dialog
      v-if="selected && selected_image"
      v-model="showImageDialog"
      scrollable
      width="auto"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Image</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <img style="max-width: 100%;" :src="selected_image" alt="" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            @click="downloadImage(selected, 'changegeolocationPhoto')"
          >
            <b-icon icon="download" aria-hidden="true"></b-icon>
            {{ $t("Download") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="closeImageDialog">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="600px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="title_wrapper">
            <span class="text-h5">Cambio GPS</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">{{ $t("Date") }} : {{ selected.date }}</h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ $t("route") }} : {{ selected.route?.name }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ $t("User") }} : {{ selected.user.name }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ $t("chain") }} : {{ selected.chain_name }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{ $t("address") }} : {{ selected.address_label }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">Lat / Lng : {{ selected.lat_lng }}</h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{ `${this.$t("newitem")} ${this.$t("address")}` }} :
                    {{ selected.new_address_label }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{ `${this.$t("newitem")} Lat / Lng` }} :
                    {{ selected.new_lat_lng }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-autocomplete
                    v-model="selected.approvalStatus"
                    :items="approvalStatusValues"
                    item-text="label"
                    item-value="value"
                    filled
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isSaving"
              @click="onSaveClick(selected)"
              ref="btnSave"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>Cambio GPS</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-btn
              color="blue darken-1 mb-2"
              @click="downloadExcelTemplateAPI('POS_CHANGE_GPS')"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon>
              Plantilla Import
            </v-btn>
            <v-btn
              color="success"
              dark
              class="ml-2 mb-2"
              :loading="isSelectingBrandSales"
              @click="onUploadExcelBrandSalesClick"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              {{ buttonTextBrandSales }}
            </v-btn>
            <input
              ref="uploaderBrandSales"
              class="d-none"
              type="file"
              accept=".xls,.xlsx"
              @change="onFileChangedBrandSales"
            />
            <v-btn
              color="warning"
              dark
              class="ml-2 mb-2"
              @click="downloadExcel"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <div
              class="status_wrapper d-flex justify-content-center align-center"
            >
              <b-card
                bg-variant="dark"
                v-bind:header="$t('pendingapproval')"
                class="text-center mr-2"
                style="width:200px"
              >
                <b-card-text>{{ pendingCountApproval }}</b-card-text>
              </b-card>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="4"> </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRefroutes"
              id="poschangegeolocation_table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="data"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaldata"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import _ from "lodash";

export default {
  name: "changegeolocationpos",
  data: function() {
    return {
      filterlist_fetch_url: "pos/changegeolocation/getfilterlist",
      filterable_col_list: [
        "date",
        "routeId",
        "userId",
        "address_label",
        "new_address_label",
        "chain_name",
        "approvalStatus"
      ],
      filterCriteria: {
        date: {},
        routeId: {},
        userId: {},
        address_label: "",
        new_address_label: "",
        chain_name: {},
        approvalStatus: {}
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      showEmpty: false,

      totaldata: 0,
      data: [],
      approvalStatusValues: [],
      pendingCountApproval: 0,
      pendingCountAdmin: 0,

      /********** Import and Export Excel **********/
      isSelectingBrandSales: false,
      selectedFileBrandSales: null,
      defaultbuttonTextBrandSales: "Import",

      /********** image dialog options **********/
      showImageDialog: false,
      selected_image: null,

      /********** create or edit dialog options **********/
      showEdit: false,
      selected: null,
      editedIndex: -1,
      valid: true,
      requiredRules: [v => !!v || "Required"],
      isSaving: false
    };
  },
  watch: {},
  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "isSPV",
      "isManager",
      "isSubAdmin",
      "isAdmin",
      "user"
    ]),
    buttonTextBrandSales() {
      return this.isSelectingBrandSales
        ? "Uploading..."
        : this.defaultbuttonTextBrandSales;
    },
    col_id() {
      return {
        key: "id",
        title: "ID",
        field: "id",
        align: "left",
        width: 100,
        sortBy: "desc"
      };
    },
    col_date() {
      return {
        key: "date",
        title: this.$t("Date"),
        field: "date",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["from"]}
                  value={this.filterCriteria["date"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["to"]}
                  value={this.filterCriteria["date"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "date")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_route() {
      return {
        key: "routeId",
        title: this.$t("route"),
        field: "routeId",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.route && row.route.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["routeId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`routeId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["routeId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "routeId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_user() {
      return {
        key: "userId",
        title: "GPV",
        field: "userId",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>{row.user && row.user.name + " " + row.user.surname}</span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["userId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`userId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["userId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "userId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_posId() {
      return {
        key: "posId",
        title: "Id POS",
        field: "posId",
        align: "left",
        width: 100,
        sortBy: ""
      };
    },
    col_chain_name() {
      return {
        key: "chain_name",
        title: `${this.$t("chain")}`,
        field: "chain_name",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["chain_name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`chain_name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["chain_name"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "chain_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_address_label() {
      return {
        key: "address_label",
        title: `${this.$t("address")}`,
        field: "address_label",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["address_label"]}
                  on-input={value =>
                    (this.filterCriteria["address_label"] = value)
                  }
                  placeholder="Search Address"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "address_label")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_lat_lng() {
      return {
        key: "lat_lng",
        title: `Lat / Lng`,
        field: "lat_lng",
        align: "left",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.lat_lng}</span>;
        }
      };
    },
    col_new_address_label() {
      return {
        key: "new_address_label",
        title: `${this.$t("newitem")} ${this.$t("address")}`,
        field: "new_address_label",
        align: "left",
        width: 250,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.new_address_label}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["new_address_label"]}
                  on-input={value =>
                    (this.filterCriteria["new_address_label"] = value)
                  }
                  placeholder="Search Address"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "new_address_label")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_new_lat_lng() {
      return {
        key: "new_lat_lng",
        title: `${this.$t("newitem")} Lat / Lng`,
        field: "new_lat_lng",
        align: "left",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.new_lat_lng}</span>;
        }
      };
    },
    col_photo() {
      return {
        key: "photo",
        title: this.$t("Photo"),
        field: "photo",
        align: "left",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          if (row.changegeolocationPhoto !== null) {
            let thumbnail_url = this.getImageUri(row.changegeolocationPhoto);
            return (
              <img
                style="border: 1px solid #aaa; cursor: pointer; max-width: 100%;"
                src={thumbnail_url}
                alt=""
                on-click={() =>
                  this.onItemShowImageDialog(row, row.changegeolocationPhoto)
                }
              />
            );
          } else {
            return <span>&nbsp;</span>;
          }
        }
      };
    },
    col_approvalStatus() {
      return {
        key: "approvalStatus",
        title: `${this.$t("status")}`,
        field: "approvalStatus",
        align: "left",
        width: 180,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.approvalStatusLabel}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["approvalStatus"]}
                  dataList={this.approvalStatusValues}
                  filterCriteria={this.filterCriteria["approvalStatus"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "approvalStatus")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    columns() {
      return [
        this.col_id,
        this.col_date,
        this.col_route,
        this.col_user,
        this.col_posId,
        this.col_chain_name,
        this.col_address_label,
        this.col_lat_lng,
        this.col_new_address_label,
        this.col_new_lat_lng,
        this.col_photo,
        this.col_approvalStatus,
        {
          key: "actions",
          title: this.$t("edititem"),
          field: "actions",
          align: "center",
          width: 100,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-btn
                  color="blue darken-1"
                  text
                  on-click={() => this.onItemEdit(row)}
                >
                  <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
                </v-btn>
              </div>
            );
          }
        }
      ];
    }
  },
  methods: {
    getImageUri(imgStatic) {
      if (imgStatic && imgStatic?.type === "file") {
        return ApiService.getFileUri(imgStatic);
      }
      return ApiService.getImageUri(imgStatic);
    },
    onItemShowImageDialog(item, image) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.selected_image = this.getImageUri(image ? image : null);
      this.showImageDialog = true;
    },
    closeImageDialog() {
      this.showImageDialog = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    async downloadImage(selected, downloadFile) {
      try {
        const response = await ApiService.get(this.selected_image, {
          responseType: "arraybuffer"
        });
        download(response, "download.png");
      } catch (error) {
        // this.close();
      }
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (["address_label", "new_address_label"].includes(cancelFilterKey)) {
        this.filterCriteria[cancelFilterKey] = null;
      } else {
        this.filterCriteria[cancelFilterKey] = {};
      }
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#poschangegeolocation_table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    async getDataFromApi() {
      this.show();
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector:
          "#poschangegeolocation_table .filterable-column"
      });
      let fetch_url = "pos/changegeolocation";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.data = resp.data;
        if (this.data.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaldata = resp.total;
        this.approvalStatusValues = resp.approvalStatusValues;
        this.pendingCountApproval = resp.pendingCountApproval;
        this.pendingCountAdmin = resp.pendingCountAdmin;
        this.$store.dispatch("auth/getGlobalState");
        this.close();
      } catch (error) {
        this.close();
      }
    },
    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    createSelectedModel(item) {
      let model = { ...item };
      return model;
    },
    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        let body = _.omit(item, []);
        if (body.id) {
          try {
            await ApiService.put(`pos/changegeolocation/${body.id}`, body);
            logInfo("Éxito !");
            this.showEdit = false;
            this.$store.dispatch("auth/getGlobalState");
            this.getDataFromApi();
            this.isSaving = false;
          } catch (error) {
            this.isSaving = false;
          }
        }
      }
    },
    async downloadExcel() {
      this.show();
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector:
          "#poschangegeolocation_table .filterable-column"
      });
      let fetch_url = "pos/changegeolocation/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "change_gps.xlsx");
      this.close();
    },
    onUploadExcelBrandSalesClick() {
      this.isSelectingBrandSales = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingBrandSales = false;
        },
        { once: false }
      );
      this.$refs.uploaderBrandSales.click();
    },
    async onFileChangedBrandSales(e) {
      this.selectedFileBrandSales = e.target.files[0];
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelectingBrandSales = true;
        let data = new FormData();
        data.append("file", this.selectedFileBrandSales);
        this.$refs.uploaderBrandSales.value = "";
        try {
          const response = await ApiService.post(
            `pos/changegeolocation/uploadexcel/`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelectingBrandSales = false;
          await this.getDataFromApi();
        } catch (error) {
          console.log(error);
          this.isSelectingBrandSales = false;
        }
      }
    },
    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style>
span.required {
  color: #f00;
  font-weight: bold;
}
.theme--light.v-text-field--filled.v-input--is-readonly.readonly_component
  > .v-input__control
  > .v-input__slot {
  background: #c3c3c3;
}
.status_wrapper {
  margin-top: 0;
  color: #000;
}
.status_wrapper .bg-dark {
  background-color: #9f9f9f !important;
}
</style>
